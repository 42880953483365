import { http } from "../http";

export class CommonService {
  private static INSTANCE = new CommonService();

  static get instance() {
    return this.INSTANCE;
  }

  public findOneAgency(id: string) {
    let endpoint = "";
    endpoint = `api/agencies/${id}?populate=deep`;

    return http.strapiGet(endpoint).then((response) => {
      return response;
    });
  }

  public createAgency(agencyPayload: any) {
    const formdata = new FormData();
    for (
      let index = 0;
      index < agencyPayload.organization_Info.logo.length;
      index++
    ) {
      formdata.append(
        "files.organization_Info.logo",
        agencyPayload.organization_Info.logo[index]
      );
    }
    for (
      let index = 0;
      index < agencyPayload.organization_Info.featured.length;
      index++
    ) {
      formdata.append(
        "files.organization_Info.featured",
        agencyPayload.organization_Info.featured[index]
      );
    }
    delete agencyPayload.organization_Info.logo;
    delete agencyPayload.organization_Info.featured;

    formdata.append("data", JSON.stringify(agencyPayload));
    const endpoint = "api/agencies";
    return http.strapiPost(endpoint, formdata).then((response) => {
      return response;
    });
  }

  public logInWithCredential(
    usernameOrEmail: string,
    password: string,
    loginType: string
  ) {
    const data = {
      grantType: loginType,
    };
    const endpoint = "user/signin";
    const token = `${usernameOrEmail}:${password}`;
    const headers = http.getBasicHeaders(token);
    return http.post(endpoint, headers, data).then((response) => {
      return {
        userDetails: {
          displayName: response.data.first_name + " " + response.data.last_name,
          email: response.data.email,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          tenantId: response.data.tenant_id,
          userId: response.data.user_id,
          roles: response.data?.permissions,
        },
        access_token: response.data?.access_token,
      };
    });
  }
}

export const commonService = CommonService.instance;
