import { Component, Vue } from "vue-property-decorator";
import CarouselComponent from "@/components/carousel/main-carousel.component.vue";
import OurProgramsComponent from "@/components/our-programs/our-programs.component.vue";
import CadreProgramComponent from "@/components/cadre-program/cadre-program.component.vue";
import PeerSpecialistsTestimonailComponent from "@/components/peer-specialists-testimonial/peer-specialists-testimonial.component.vue";
import MoreInformationComponent from "@/components/more-information/more-information.component.vue";

@Component({
  components: {
    CarouselComponent,
    CadreProgramComponent,
    OurProgramsComponent,
    PeerSpecialistsTestimonailComponent,
    MoreInformationComponent,
  },
})
export default class HomePage extends Vue {}
