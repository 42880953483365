import { http } from "@/providers/http";
export class PeerSpecialistsApi {
  private static INSTANCE = new PeerSpecialistsApi();

  static get instance() {
    return this.INSTANCE;
  }

  //PEER SPECIALIST SEARCH

  public getPeerSpecialist(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["zip"]) {
      const filterKey = `zip=${filters["zip"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["firstName"]) {
      const filterKey = `firstName=${filters["firstName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["lastName"]) {
      const filterKey = `lastName=${filters["lastName"]}`;
      filterKeys.push(filterKey);
    }
    const data = { grantType: "credential" };
    const filterStr = filterKeys.join("&");
    const endPoint = `cadre/peers/specialists?${filterStr}`;
    const anonymousToken = localStorage.getItem("anonymousToken");
    let headers = {};
    headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${anonymousToken}`,
    };
    return http.get(endPoint, headers, data).then((response) => {
      return response.data;
    });
  }
}

export const peerSpecialistsApi = PeerSpecialistsApi.instance;
