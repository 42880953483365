import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { utils } from "@/utils/utils";
import { commonService } from "@/providers/services/common-service";
import { appConfigService } from "@/providers/services/app/app-config";
import { peerSpecialistsApi } from "@/providers/apis/peer-specialists";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { PEER_FRONT_FACING_LISTING_QUERY } from "@/graphql/peer-front-facing-listing-query";
import {
  PeerFrontFacingListingData,
  filters,
} from "@/models/peer-front-facing-listing/peer-front-facing-listing.interface";
import moment from "moment";

@Component({
  name: "peer-listing",
  components: {
    AppLoaderComponent,
  },
})
export default class PeerFrontFacingListingComponent extends Vue {
  public peerSpecialitsData: PeerFrontFacingListingData[] = [];
  public filterDetails: filters = {} as filters;
  public isSearchData = false;
  public isLoading = false;
  public isDataEmpty!: boolean;
  public offset = 0;
  public limit = 20;
  public page = 1;
  public currentPage = 1;
  public totalItem = 0;
  public searchByFirstName = "";
  public searchByLastName = "";
  public zip = "";
  public errorMessage = "";
  public downloadFullSearchList: any;
  public filteredData: any;
  public json_data: any = [];
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
  };
  public tableFields = [
    { key: "firstName", label: "First Name", sortable: false },
    { key: "lastName", label: "	Last Name", sortable: false },
    // {
    //   key: "zip",
    //   label: "Zipcode",
    //   sortable: false,
    //   thClass: "zipcode",
    //   tdClass: "zipcode",
    // },
    {
      key: "programFullName",
      label: "Program Name",
      sortable: false,
      thClass: "program-name pointer-events",
      tdClass: "program-name-td",
    },
    {
      key: "orginalCertificateDate",
      label: "Certification Date",
      sortable: false,
      thClass: "application_status pointer-events",
      tdClass: "Awarded-date-status",
    },
    {
      key: "expiryDate",
      label: "Expiration Date",
      sortable: false,
      thClass: "expiry_date pointer-events",
      tdClass: "expiryDate",
    },
  ];
  public dateBuilder: any;

  public async created() {
    this.isLoading = true;
    this.dateBuilder = moment(new Date()).format("DDMMYYYY");
    commonService
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });

    await this.$apollo
      .query({
        query: gql`query ${PEER_FRONT_FACING_LISTING_QUERY}`,
      })
      .then((result) => {
        this.peerSpecialitsData = result.data.peerFrontFacingListings.data;
      });
  }

  public async getPeerLists() {
    this.isLoading = true;
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.zip = this.zip;
    this.filterDetails.firstName = this.searchByFirstName.toLowerCase();
    this.filterDetails.lastName = this.searchByLastName.toLowerCase();
    await peerSpecialistsApi
      .getPeerSpecialist(this.filterDetails)
      .then((data) => {
        this.filteredData = data.peers;
        this.totalItem = data.totalCount;
        for (const item of this.filteredData) {
          item.orginalCertificateDate = moment(
            item.orginalCertificateDate
          ).format("MM-DD-YYYY");
          item.expiryDate = moment(item.expiryDate).format("MM-DD-YYYY");
          if (item.program === "CPRS") {
            item.programFullName = "Certified Peer Recovery Specialist";
          } else if (item.program === "CFSS") {
            item.programFullName = "Certified Family Support Specialist";
          } else if (item.program === "CYAPSS") {
            item.programFullName =
              "Certified Young Adult Peer Support Specialist";
          }
        }
        this.isSearchData = true;
        this.isLoading = false;
        if (this.filteredData.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public resetData() {
    this.offset = 0;
    this.currentPage = 1;
    this.searchByFirstName = "";
    this.searchByLastName = "";
    this.isSearchData = false;
    this.filteredData = [];
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public exportPeerList() {
    let programFullName = "";
    this.filterDetails.limit = null;
    this.filterDetails.offset = 0;
    this.filterDetails.zip = this.zip;
    this.filterDetails.firstName = this.searchByFirstName.toLowerCase();
    this.filterDetails.lastName = this.searchByLastName.toLowerCase();
    peerSpecialistsApi.getPeerSpecialist(this.filterDetails).then((data) => {
      this.downloadFullSearchList = data.peers;
      if (this.downloadFullSearchList.length > 0) {
        let peerSpecialitsSearch;
        this.json_data = [];
        this.downloadFullSearchList.forEach((data: any) => {
          if (data.program === "CPRS") {
            programFullName = "Certified Peer Recovery Specialist";
          } else if (data.program === "CFSS") {
            programFullName = "Certified Family Support Specialist";
          } else if (data.program === "CYAPSS") {
            programFullName = "Certified Young Adult Peer Support Specialist";
          }
          peerSpecialitsSearch = {
            firstName: data.firstName,
            lastName: data.lastName,
            zipcode: data.zip,
            programName: programFullName,
            orginalCertificateDate: data.orginalCertificateDate,
            expiryDate: data.expiryDate,
          };

          this.json_data.push(peerSpecialitsSearch);
        });
        const peerWorkbook = new Workbook();
        const worksheet = peerWorkbook.addWorksheet("Peer Specialists");
        worksheet.columns = [
          { header: "First Name", width: 25 },
          { header: "Last Name", width: 25 },
          { header: "Zipcode", width: 15 },
          { header: "Program Name", width: 40 },
          { header: "Initial Awarded Date", width: 25 },
          { header: "Expiration Date", width: 25 },
        ];
        for (const x1 of this.json_data) {
          const x2 = Object.keys(x1);
          const temp = [];

          for (const y of x2) {
            temp.push(x1[y]);
          }
          worksheet.addRow(temp);
        }
        const fname = "Peer Specialists";
        peerWorkbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs.saveAs(blob, fname + "-" + this.dateBuilder + ".xlsx");
        });
      }
    });
  }

  public sortChanged(event: Event) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getPeerLists();
  }
}
