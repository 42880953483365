import { DOC_LIBRARY_QUERY } from "@/graphql/doc-library-query";
import { docLibraryData } from "@/models/common-interface/common.interface";
import apolloClient from "@/vue-apollo";
import gql from "graphql-tag";

export class Utils {
  static get instance() {
    return this.INSTANCE;
  }

  public checkIsMobileView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth < 567;
  }

  public checkIsTabView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth < 1024;
  }

  public checkIsDesktopView() {
    if (typeof window === "undefined") {
      return true;
    }
    return window.innerWidth > 1024;
  }

  public checkIsIpadView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth > 991 && window.innerWidth < 1200;
  }

  public telCharacterValidate(evt: any, length?: number) {
    const keysAllowed: string[] = [
      "-",
      "(",
      ")",
      " ",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    } else {
      if (evt.target.value.length === length) {
        evt.preventDefault();
      }
    }
  }

  public avoidNumbersAndSpecialCharacters(e: any) {
    const char = "~`!@#$%^&*()+=-_[]\\';,./{}|\":<>?0123456789";
    if (char.includes(e.key)) {
      e.preventDefault();
    }
  }

  public onlyNumberKey(evt: any, length?: number) {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    } else {
      if (evt.target.value.length === length) {
        evt.preventDefault();
      }
    }
  }

  public async getAllDoc() {
    let value: docLibraryData[] = [];
    await apolloClient
      .query({
        query: gql`query ${DOC_LIBRARY_QUERY}`,
      })
      .then((result) => {
        value = result.data.docsLibraries.data;
      });
    return value;
  }

  public filterDoc(data: docLibraryData[], keyName: string) {
    let value: docLibraryData = {} as docLibraryData;
    data.find((doc: docLibraryData) => {
      if (doc.attributes.keyName === keyName) {
        value = doc;
      }
    });
    return value?.attributes?.document.data.attributes;
  }

  public errorMessage(error: any): string {
    let message = "";
    switch (error.response.status) {
      case 401:
        message = "Session was expired";
        break;
      case 404:
        message = error.response.data.message;
        break;
      case 409:
        message = error.response.data.message;
        break;
      case 502:
        message = "Please try again after some time";
        break;
      default:
        message = error.response.data.message;
        break;
    }
    return message;
  }

  private static INSTANCE = new Utils();
}
export const utils = Utils.instance;
